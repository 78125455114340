* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

body {
  position: relative;
  background: linear-gradient(60deg,
      #579533,
      #f53055,
      #ef4e7b,
      #a166ab,
      #0b101a,
      #1098ad,
      #07b39b,
      #122016);
  animation: spin 10s alternate infinite;
  background-size: 300% 300%;
}

ul.n-ul {
  list-style: none;
  display: grid;
  grid-template-rows: 45px;
  justify-content: center;
  background-color: black;
  color: white;
  align-items: center;
  overflow: hidden;
}

.n-logo {
  padding-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.n-img {
  width: 35px;
}

.n-logoName {
  height: 100%;
  font-weight: bold;
  font-size: 25px;
  margin: auto 0;
  letter-spacing: 2px;
  padding-left: 2px;
}

.a-grid {

  position: relative;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  place-items: center;

}

#box {
  height: 200px;
  width: 200px;
  color: white;
  overflow: hidden;
  background: black;
}

.a-gridItem {
  position: relative;
  border-width: 10px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 25px;
  place-items: center;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.212);
  box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.66);
}

.a-gridItem::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: red;
  z-index: -1;
}

.a-itemLogo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("./Assests/images/animated-background.gif");
  background-size: cover; */
}

.a-name {
  padding-top: 10px;
}

.a-play {
  font-size: 20px;
  position: fixed;
  bottom: 20px;
  right: 5px;
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px 1px lightgray;
}

@keyframes spin {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.a-icon {
  width: 50px;
}

.n-toggle {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.a-popUp {
  width: 70vw;
  position: fixed;
  bottom: 90px;
  right: 5px;
  animation: slide 500ms ease-in;
}

@keyframes slide {
  0% {
    right: -40vw;
  }

  100% {
    right: 5px;
  }
}

@media only screen and (min-width: 600px) {
  ul.n-ul {
    grid-template-columns: 0.85fr 100px;
    overflow: hidden;
  }

  .a-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .n-toggle {
    display: flex;
    justify-content: space-around;
  }

  .a-popUp {
    width: 20vw;
  }
}

@media only screen and (max-width: 1024px) {
  ul.n-ul {
    grid-template-columns: 1fr 100px;
    overflow: hidden;
  }

  .a-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .a-popUp {
    width: 30vw;
  }
}

.volume-slider {
  border-top: 1px solid rgba(255, 255, 255, 0.212);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  ul.n-ul {
    grid-template-columns: 1fr 70px;

    overflow: hidden;
  }

  .a-grid {
    grid-template-columns: 1fr;
  }

  #box {
    height: 70vw;
    width: 70vw;
  }

  .a-popUp {
    width: 50vw;
  }
}